import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";



export const StyledButton = styled.button`
:hover {
	color: #000;
	background-color: #fff;
}
  background-color: #000;
    border-radius: 8px;
    color: #fad221;
    border: 0;
    font-weight: 700;
    font-size: 16px;
    padding: 5%;
    margin: auto auto;
    transition: .3s;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--secondary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
  const networks = {
    polygon: {
      chainId: `0x${Number(137).toString(16)}`,
      chainName: "Polygon Mainnet",
      nativeCurrency:{
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18
      },
      rpcUrls: ["https://polygon-rpc.com/"],
      blockExplorerUrls: ["https://polygonscan.com/"]
    }
  };

  const changeNetwork = async ({ networkName, setFeedback }) => {
    try {
      if (!window.ethereum) throw new Error ("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            ...networks[networkName]
          }
        ]
      });
    } catch (err) {
      setFeedback(err.message);
    }
  };




function App() {
  
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [netid, setNetId] = useState(`0x89`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  async function getNetworkAndChainId () {
    try {
      const chainId = await window.ethereum.request({
        method: 'eth_chainId',
      })
      setNetId(chainId)

    } catch (err) {
      console.error(err)
    }
  };


  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Something went wrong, try again.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };
  const handleNetworkSwitch = async (networkName) => {
    setFeedback();
    await changeNetwork({ networkName, setFeedback });  
  };

  
  const networkChanged = (chainId) => {
    console.log({ chainId});
    setNetId(chainId);
    dispatch(connect());getData();
  };

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
     window.ethereum.on("chainChanged", networkChanged); 
    }
    

    return() => {
      window.ethereum.removeListener("chainChanged", networkChanged);
    };
  }, []);

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };


  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 500) {
      newMintAmount = 500;
    }
    setMintAmount(newMintAmount);
  };
  

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account)); 

    }
    };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

var totalCost = 20;
{
 totalCost = mintAmount*CONFIG.DISPLAY_COST;
}


  return (  
  
    <div class="tcyc-bg p-4 m-auto row">
        <div class="m-auto col-lg-3 col-12">
          <h3 class="buy-ape-title">MINT A CITIZEN</h3>
        </div>
    {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ?
     (
      <div class="m-auto col-lg-4 col-12 offset-lg-1">
        <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }}>The sale has ended.</s.TextTitle>
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>You can still find {CONFIG.NFT_NAME} on</s.TextDescription>               
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>{CONFIG.MARKETPLACE}</StyledLink>                
              </div>
      ):(
              <><div class="m-auto col-lg-4 col-12 offset-lg-1">
                <p class="common-p mb-lg-0">{mintAmount} {CONFIG.SYMBOL} costs {totalCost}{" "}{CONFIG.NETWORK.SYMBOL}.               
                Excluding gas fees.</p>
                
                {blockchain.errorMsg !== "" ? (<><p class="common-p mb-lg-0">{blockchain.errorMsg === "1" ? (null) : (blockchain.errorMsg)}</p></>) : null}
                {blockchain.account === "" ||blockchain.smartContract === null ? (null):(<><s.TextDescription>{feedback}</s.TextDescription></>)}
                
                </div>
                {blockchain.account === "" ||blockchain.smartContract === null ? (
                 
                  <div class="m-auto col-lg-3 col-12 offset-lg-1">               
                      {blockchain.errorMsg === "1" ? (<><StyledButton type="button" onClick={(e) => {e.preventDefault();window.open('https://metamask.io');}}>INSTALL METAMASK WALLET</StyledButton> </>) : (<>{netid === "0x89" ? (<><StyledButton type="button" onClick={(e) => {e.preventDefault();dispatch(connect());getData();getNetworkAndChainId();}}>CONNECT YOUR WALLET</StyledButton> </>) : (<> <StyledButton type="button" onClick={(e) => {e.preventDefault(); handleNetworkSwitch("polygon");}}>SWITCH TO POLYGON NETWORK</StyledButton>  </>)}                    
              </>)}                    
           
                        </div>
      ):(
              
                
              
              <>
                       <div class="m-auto col-lg-3 col-12 offset-lg-1">
                       <StyledButton type="button" disabled={claimingNft ? 1 : 0} onClick={(e) => {e.preventDefault(); claimNFTs(); getData();}}>{claimingNft ? "BUSY, CHECK YOUR METAMASK" : "CLICK TO MINT YOUR NFT"}</StyledButton>
                        </div>
              </>
                    )}
              </>
            )}
            
    </div>
  );
}

export default App;
